export const theme = {
  fonts: {
    primary: `Meta, "Helvetica Neue", Helvetica, Arial`,
    accent: `"Meta Serif", Georgia`,
  },
  colors: {
    primary: '#0D0550',
    primaryIntense: '#11066F',
    secondary: '#20BB7D',
    darkTint: '#312E4E',
    lightTint: '#FBFFF8',
    lightTintMid: '#F6F9F8',
    lightTintDarker: '#E9F0ED',
    tintOverlay: '#264D3929',
    alert: '#E24432',
    alertIntense: '#CF142B',
  },
  breakpoints: { medium: 640, large: 1280, xl: 1440, compactNav: 1000 },
  mq: { small: 'max-width: 639px' },
  radius: 6,
}

export const RECAPTCHA_SITE_KEY = '6LeZEH8UAAAAAIjdPeK4Z04gAYqI-gA5FsFnpjKX'
export const SENTRY_DSN =
  'https://92fc15fde4d24b1db1b59983a5091994@o108373.ingest.sentry.io/1336406'
export const IPDATACO_KEY =
  '7444fd6b30144f0b4892c554c6089f61ef1a67d2b9338a7ef7c3a786'

export const sanity = {
  projectId: 'y9gxyyq5',
  dataset: 'production',
}
