import React, { createContext, useState } from 'react'

const AppContext = createContext({})

const AppContextProvider = ({
  overlayVisible: initialOverlayVisible = false,
  children,
}) => {
  const [overlayVisible, setOverlayVisible] = useState(initialOverlayVisible)

  const context = {
    overlayVisible,
    setOverlayVisible,
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

export { AppContext, AppContextProvider }
