import React, { createContext, useState, useEffect } from 'react'

import { checkPhonesAnswered } from '../../lib/tel-hours'

const ContactContext = createContext({})

const ContactContextProvider = (props) => {
  const { children } = props

  const [phonesAnswered, setPhonesAnswered] = useState(null)

  const context = {
    phonesAnswered,
  }

  // TODO trigger update in xx milliseconds when open/close
  useEffect(() => {
    const updatePhonesAnswered = () => {
      const linesOpen = checkPhonesAnswered(new Date())
      if (linesOpen !== phonesAnswered) setPhonesAnswered(linesOpen)
    }

    if (phonesAnswered === null) updatePhonesAnswered()
    const pollPhoneAnswered = setInterval(updatePhonesAnswered, 1000 * 60) // poll every 60 seconds
    return () => {
      clearInterval(pollPhoneAnswered)
    }
  }, [phonesAnswered])

  return (
    <ContactContext.Provider value={context}>
      {children}
    </ContactContext.Provider>
  )
}

export { ContactContext, ContactContextProvider }
